.alert-global {
  background-color: #fff;
}

.alert-global.open {
  padding: 10px;
}
.alert-text * {
  font-size: 13px;
}
