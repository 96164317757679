:root {
  /* PAPER */
  --paper-height: 270mm;
  --paper-width: 210mm;
  --paper-mg-top: 2.5cm;
  --paper-mg-bottom: 2cm;
  --paper-mg-left: 3.5cm;
  --paper-mg-right: 1.5cm;
  --paper-font-size-11: 11pt;
  --paper-font-size-14: 14pt;
  --paper-font-size-16: 16pt;
  --paper-font-size-18: 18pt;
  --paper-font-size-20: 20pt;
  --paper-line-spacing: 1.2;
  --paper-font-family: 'Arial';
  --paper-text-color: #000;
  --paper-font-size: 10pt;

  --left-sidebar-document: 470px;
  --right-sidebar-document: 470px;

  --toolbar-editors-width: 736px;
  --header-height: 56px;
  --breadcrumb-height: 52px;
  --footer-height: 47px;
  --layout-main-content-padding-inline: 40px;
  --layout-main-content-height: calc(100vh - var(--header-height) - var(--breadcrumb-height) - var(--footer-height));

  --steps-height: 50px;
  --btn-row-height: 50px;
  --contract-sidebar-width: 20vw;
  --contract-header-color: hsl(0deg 0% 98%);
  --highlight-color: #ffff00;

  --font-family-inter: 'Inter', sans-serif;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: var(--font-family-inter) !important;
}

ol,
ul {
  margin-left: calc(16px + 40px);
}
li {
  /* list-style: none; */
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}
html {
  overflow: hidden;
}
.App {
  position: relative;
  text-align: center;
  /* height: 100%; */
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.approve {
  background-color: green;
  color: #fff;
}

.reject {
  color: #ff2424;
}
.reject:hover {
  color: #f77373 !important;
  opacity: 0.8;
}

.loading-content {
  padding: 50px;
  background: none;
  border-radius: 4px;
}
#loading {
  position: absolute;
  /* height: 100vh; */
  left: 0;
  /* width: 100vw; */
  top: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  justify-content: center;
  /* gap: 8px; */
  z-index: 1000;
  background: #3333332e;
}
.ant-spin-container.ant-spin-blur::after {
  opacity: 0;
}

.table-row-light {
  background-color: #ffffff;
}
.table-row-dark {
  background-color: #f5f3f387;
}
.ant-btn.ant-btn-round {
  padding-inline-start: 10px;
  padding-inline-end: 10px;
}
.ant-table-body {
  overflow-x: auto !important;
}
