.ant-menu-title-content {
  text-align: left;
}
.ant-menu-item-icon + span {
  margin-inline-start: 20px !important;
}
.ant-menu-item-group-title {
  text-align: left;
}
.ant-layout-content {
  padding: 0px;
  margin: unset !important;
}
.ant-layout-footer {
  padding: 16px 50px !important;
}
.layout-main .ant-layout-sider {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.ant-layout-sider-trigger {
  position: unset !important;
}
.ant-breadcrumb {
  padding: 0 16px;
}
.ant-menu {
  overflow: auto !important;
}

.layout-main * {
  font-family: var(--font-family-inter) !important;
}
.layout-main-content {
  background-color: #fff;
  padding: 20px;
  height: var(--layout-main-content-height);
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
