.ant-modal.new-document-modal {
  width: 1000px !important;
  height: 550px !important;
}
.ant-list.list-templates .ant-list-item {
  cursor: pointer;
}
.ant-list.list-templates .ant-list-item:hover {
  background-color: #d8e3f3;
}
