.mark-read-span {
  color: dodgerblue;
  cursor: pointer;
}
.notification-item {
  /* cursor: pointer; */
}
.notification-item:hover {
  background-color: #e6f4ff;
}
.ant-list-item-meta-avatar {
  margin-inline-start: 10px;
}
.ant-list-item-meta {
  align-items: center !important;
}
.ant-list-item-action {
  margin-inline-start: 0 !important;
}
.notification-date {
  color: dodgerblue;
}
.notification-content {
  cursor: pointer;
}

.notification-content:hover {
  opacity: 0.6;
}

.ant-popover-placement-bottom .ant-popover-arrow {
  left: 50%;
  transform: translateX(-50%) translateY(calc(-100% + 1px));
}

.notification-space .ant-popover .ant-popover-inner {
  padding: 0;
}
