.header-wrapper {
  padding: 0 16px;
}
.header-title {
  color: #fff;
  text-align: left;
  display: block;
  font-size: 16px;
  font-weight: 600;
}
.header-user {
  text-align: right;
}
.topbar-username {
  color: #fff;
}

.content-panel {
  display: flex;
  flex-direction: column;
  width: 200px;
  align-items: flex-start;
  gap: 6px;
}
